@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fill-anim {
  from {
    fill: transparent;
    width: 100%;
  }
  to {
    fill: white;
  }
}

@keyframes remove-anim {
  to {
    width: 0%;
    opacity: 0;
  }
}

@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes moveToZero-anim {
  to {
    transform: translate(0%, 0%);
  }
}

* {
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

#root {
  padding-top: 0px !important;
}

.material-icons {
  font-size: 1.25rem;
  vertical-align: middle;
}
